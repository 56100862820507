import React, { Component, Fragment } from 'react';
import { BarLoader } from 'react-spinners'
import { Redirect, useHistory } from 'react-router-dom';
import { css } from "@emotion/core";
import User from './auth_model';
import Axios from 'axios';
import * as Config from '../Constant/config';
import icon from '../assets/img/inyek_ico.png';

const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        width: 100%
        `;

function withHistory(Component) {
    return (props) => {
        const history = useHistory()
        return <Component {...props} {...history} />
    }
}

class Index extends Component {

    componentDidMount() {
        setTimeout(this.auth, 3000);

    }

    auth = () => {
        var formData = new FormData()
        formData.append("token", "7b790da14ab32775766a2c91bcccba20366ca27")

        Axios.post(Config.BASE_URL + "authy.php", formData, Config.HEADERS)
            .then(res => {
                console.log("auth:", res.data)
                User.setAuthorize(res.data.response)
                setTimeout(function () {

                }, 3000);
                if (User.getAuth()) {
                    let data = res.data.data
                    User.setUserId(data.id)
                    User.setName(data.name)
                    User.setToken(data.token)
                    User.setPayemntMethod(data.method)
                    User.setVat(data.vat)

                    this.props.history.push("/home")
                } else this.props.history.push("/403")

            }).catch(err => {
                console.log(err)
            })
    }

    render() {
        return (
            <Fragment>
                <div className="col-12 d-flex justify-content-center h-100 ">
                    <div className="align-self-center">
                        <div className="h_150 w_150 mx-auto">
                            {/* <FadeLoader color={"#7BDDFF"} css={override} width={5} height={15} loading={true} /> */}
                            <img src={icon} width="150" height="150" />
                            <BarLoader color={"#7BDDFF"} css={override} size={150} loading={true} />
                        </div>
                        {/* <p className="mt-3 font_20">Please Wait...</p> */}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withHistory(Index)
