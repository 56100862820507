import React, { useRef, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'

function CustomEffect(fn, arr) {
    const isFirst = useRef(true)
    useEffect(() => {
        if (isFirst.current) {
            isFirst.current = false
            return
        }
        return fn()
    }, arr)
}

const OperatorModel = (props) => {

    const [selected, setSelection] = useState([])

    CustomEffect(() => {
        props.onChecked(selected)
    }, [selected])

    const onChanged = (e) => {
        // console.log(e.target.checked)
        let status = e.target.checked
        let value = e.target.value

        if (status) {
            setSelection([...selected, value])
        } else {
            let filter = selected.filter(item => (item.toUpperCase() !== value.toUpperCase()))
            setSelection(filter)
        }
    }

    const getCheckStatus = (name) => {
        let isAvailable = selected.find(item=> (item.toUpperCase()===name.toUpperCase()))
        return isAvailable? true : false
    }

    return (
        <Modal
            centered
            show={props.showModel}
            onHide={props.onHide}>
            <Modal.Body className="p-3">
                <div>
                    <h5>Operator</h5>
                    {
                        props.data ?
                            <ul className="list-group mt-3">
                                {props.data.map((item, inx) => (
                                    <label key={inx} className="list-group-item border-0 py-1">
                                        <input
                                            checked={getCheckStatus(item)}
                                            onChange={onChanged}
                                            className="form-check-input me-3"
                                            type="checkbox"
                                            value={item}
                                            aria-label={item} />
                                        <span className="ml-2">{item}</span>
                                    </label>
                                ))}
                            </ul>
                            : <span>No Records found</span>
                    }
                    <div className="d-flex justify-content-end">
                        <button onClick={props.onHide} type="button" className="btn btn-link btn_noFocus text_theme_color">OK</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default OperatorModel;
