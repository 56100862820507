import React, { Fragment, useState, useEffect } from 'react';
import { IoBusOutline, IoStop } from 'react-icons/io5';
import TotalCalculation from './total_calcuation';
import * as Config from '../../Constant/config';
import axios from 'axios';
import { initSeat } from '../../Constant/constData'
import { BarLoader } from 'react-spinners';
import { css } from "@emotion/core";

const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        width: 100%
        `;


const initLayout = {
  dd_number_of_columns: "0",
  dd_number_of_rows: "0",
  dd_number_of_seats: "0",
  deck_type: "single",
  id: "1",
  name: "4 X 11 = 32",
  number_of_columns: "4",
  number_of_rows: "11",
  number_of_seats: "32"
}

/* 
  props contain->
    1. formData
    2. scheduleData
    3. ShowSeats
*/
const SeatPanel = (props) => {

  const [isLoading, setLoading] = useState(false)

  const [busSeats, setSeats] = useState([])

  const [seatLayout, setseatLayout] = useState({})

  const [selectedPrice, setSelectedPrice] = useState('all')

  const [selectedSeats, setSelectedSeat] = useState([])

  useEffect(() => {
    // console.log("on Seat Panle")

    if (props.ShowSeats)
      fetchSeats()
  }, [props.ShowSeats])

  const fetchSeats = () => {
    setSelectedSeat([])
    setLoading(true)
    let data = new FormData()
    data.append('route_schedule_id', props.scheduleData.id)
    data.append('journey_date', props.formData.date)
    axios.post(Config.BASE_URL + "get_seats.php", data, Config.HEADERS)
      .then(res => {

        // console.log("seats_plan", res.data.data.seats.seat_plan['1'])

        let seats = res.data.data.seats.seat_plan
        // console.log("seats_plan", seats)
        let seat_layout = res.data.data.seats.seat_layout
        // console.log("seat_layout",seat_layout)

        setSeats(seats)
        setseatLayout(seat_layout)

        setLoading(false)
      }).catch(err => {
        setLoading(false)
        // setSeats(initSeat)
        // setseatLayout(initLayout)
        // console.log("seats", err)
      })
  }

  const onPriceCLick = (price) => {
    setSelectedPrice(price)
    // console.log(price)
  }

  const seatAction = (seat) => {
    // console.log(selectedSeats.indexOf(seat))
    if (selectedSeats.indexOf(seat) !== -1) {
      let temp = selectedSeats.filter(item => item !== seat)
      setSelectedSeat(temp)
    } else {
      setSelectedSeat([
        ...selectedSeats,
        seat
      ])
    }
  }

  const getPriceList = () => {
    let list = []
    if (busSeats['1']) {
      busSeats['1'].map((item, inx) => {
        if (item.length !== 0) {
          if (list.indexOf(item.fare) === -1) {
            list.push(item.fare)
          }
        }
      })
    }
    if (busSeats['2']) {
      busSeats['2'].map((item, inx) => {
        if (item.length !== 0) {
          if (list.indexOf(item.fare) === -1) {
            list.push(item.fare)
          }
        }
      })
    }
    return list.sort()
  }

  let seatPrices = getPriceList()
  // console.log("list=",seatPrices)
  // console.log("selected price:: ", selectedPrice)

  const parseSeats = () => {
    var dec_1_row = []
    for (var i = 1; i <= seatLayout.number_of_rows; i++) {
      var column = []
      for (var j = 0; j < seatLayout.number_of_columns; j++) {
        let isAvailable = busSeats['1'].find(item => {
          return (~~item.row_number === i && ~~item.column_number === j)
        })

        // console.log("Aavilable: ",isAvailable)

        if (isAvailable) {
          column.push(
            <button

              onClick={() => seatAction(isAvailable)}

              key={i * (j + 1)}

              type="button"

              className={
                "opacity_1 btn seat_size btn_noFocus px-2 py-1 m-1 "
                + (
                  (isAvailable.status === -1 &&
                    selectedSeats.indexOf(isAvailable) !== -1) ? "bg_selected" :
                    (isAvailable.status === -1) ? "bg_available"
                      : (isAvailable.status === 0) ? "bg_booked"
                        : (isAvailable.status === 1) ? "bg_sold"
                          : "bg_blocked"
                )
                + " "
                + (((isAvailable.status === -1) && (selectedPrice === isAvailable.fare)) ? "selected_price_border"
                  : (isAvailable.status === -1 && selectedPrice !== 'all') ? "non_selected_price_opacity" : "")
              }

              disabled={
                (
                  (isAvailable.status === -1)
                  && ((selectedPrice === isAvailable.fare) || (selectedPrice === 'all'))
                ) ? false : true
              }
            >{isAvailable.seat_number.toUpperCase()}</button>
          )
        } else {
          column.push(
            <button
              key={i * (j + 1)}
              type="button"
              className="btn seat_size btn_noFocus px-2 py-1 m-1 " />
          )
        }
      }
      // console.log("column: ",column)

      dec_1_row.push(
        <div key={i + 2000} className="d-flex justify-content-center mt-0">
          {column}
        </div>
      )
    }

    if (~~seatLayout.dd_number_of_columns === 0 && ~~seatLayout.dd_number_of_rows === 0)
      return dec_1_row

    var dec_2_row = []
    for (var i = 0; i < seatLayout.dd_number_of_rows; i++) {
      var column = []
      for (var j = 0; j < seatLayout.dd_number_of_columns; j++) {
        let isAvailable = busSeats['2'].find(item => {
          return (~~item.row_number === i && ~~item.column_number === j)
        })

        if (isAvailable) {
          column.push(
            <button

              onClick={() => seatAction(isAvailable)}

              key={(i + 1) * (j + 1)}

              type="button"

              className={
                "opacity_1 btn seat_size btn_noFocus px-2 py-1 m-1 "
                + (
                  (isAvailable.status === -1 &&
                    selectedSeats.indexOf(isAvailable) !== -1) ? "bg_selected" :
                    (isAvailable.status === -1) ? "bg_available"
                      : (isAvailable.status === 0) ? "bg_booked"
                        : (isAvailable.status === 1) ? "bg_sold"
                          : "bg_blocked"
                )
                + " "
                + (((isAvailable.status === -1) && (selectedPrice === isAvailable.fare)) ? "selected_price_border"
                  : (isAvailable.status === -1 && selectedPrice !== 'all') ? "non_selected_price_opacity" : "")
              }

              disabled={
                (
                  (isAvailable.status === -1)
                  && ((selectedPrice === isAvailable.fare) || (selectedPrice === 'all'))
                ) ? false : true
              }
            >{isAvailable.seat_number.toUpperCase()}</button>
          )
        } else {
          column.push(
            <button
              key={(i + 1) * (j + 1)}
              type="button"
              className="btn seat_size btn_noFocus px-2 py-1 m-1 " />
          )
        }


      }
      dec_2_row.push(
        <div key={i + 1000} className="d-flex justify-content-center mt-0">
          {column}
        </div>
      )
    }

    // console.log("row: ",row)

    return (
      <div className="w_fit_content mx-auto">
        <div className="d-flex justify-content-center mb-2 px-1 bg_light_blue">
          <p className="mb-0">Lower Deck</p>
        </div>
        {dec_1_row}
        <div className="d-flex justify-content-center my-2 px-1 bg_light_blue">
          <p className="mb-0">Upper Deck</p>
        </div>
        {dec_2_row}
      </div>
    )
  }

  return (
    <Fragment>
      {
        props.ShowSeats ?
          isLoading ? <div className="mt-3">
            <BarLoader color={"#7BDDFF"} css={override} size={150} loading={isLoading} />
          </div> :
            <div className="seatContainer mt-3">

              {/* <h5 className="text_light_ash">------------------------</h5> */}

              <div className="d-flex justify-content-center align-items-center">
                <IoStop className="text_selected" size="24" /><span className="mr-1 text_selected">Selected</span>
                <IoStop className="text_available" size="24" /><span className="mr-1 text_available">Available</span>
                <IoStop className="text_booked" size="24" /><span className="mr-1 text_booked">Booked</span>
                <IoStop className="text_sold" size="24" /><span className="mr-1 text_sold">Sold</span>
              </div>

              {
                (seatPrices.length > 1) ?
                  <div className="d-flex justify-content-center align-items-center mt-2">
                    <button
                      type="button"
                      onClick={() => onPriceCLick('all')}
                      className={"btn btn-light border mx-1 btn_noFocus p-0 px-1 font_14 "
                        + ((selectedPrice === 'all') ? "bg_theme_color" : "bg-white")}>All</button>
                    {
                      seatPrices.map((p, i) => (
                        <button
                          key={i}
                          type="button"
                          onClick={() => onPriceCLick(p)}
                          className={"btn btn-light border mx-1 btn_noFocus p-0 px-1 font_14 "
                            + ((selectedPrice === p) ? "bg_theme_color" : "bg-white")}>{p}</button>
                      ))
                    }
                  </div> :
                  ""
              }

              {/* 
                             status = -1 (seat available)
                             status = 0 (seat booked)
                             Status = 1 (seat sold)
                             Status = 2 (seat blocked)  
                             */}
              <div className="mt-3">
                {
                  seatLayout ?
                    parseSeats()
                    : ""
                }
              </div>
              <TotalCalculation formData={props.formData} scheduleData={props.scheduleData} selectedSeats={selectedSeats} E_Class="E-Class" /> {/** the E_Class Value will pass from real data */}
            </div>
          : ""
      }

    </Fragment>
  )
}


export default SeatPanel;