import React, { Component, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'
import { IoListCircle, IoNavigateCircleOutline, IoArrowUndo } from 'react-icons/io5'
import Axios from 'axios'
import * as Config from './../../Constant/config'
import { BarLoader } from 'react-spinners';
import { css } from "@emotion/core";
import { FaFilter } from 'react-icons/fa';

const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        width: 100%
        `;

const initData = [
    {
        "source": "Dhaka",
        "destination": "Rajshahi",
        "id": "1",
        "response": true
    },
    {
        "source": "Dhaka",
        "destination": "Tangail",
        "id": "2",
        "response": true
    },
    {
        "source": "Dhaka",
        "destination": "Sirajgonj",
        "id": "3",
        "response": true
    },
    {
        "source": "Tangail",
        "destination": "Rajshahi",
        "id": "4",
        "response": true
    },
    {
        "source": "Dhaka",
        "destination": "Pabna",
        "id": "5",
        "response": true
    },
    {
        "source": "Dhaka",
        "destination": "Madhupur",
        "id": "9",
        "response": true
    },
    {
        "source": "Dhaka",
        "destination": "Cox-bazar",
        "id": "12",
        "response": true
    }
]

const ChooseRoute = (props) => {

    const [isLoading, setLoading] = useState(false)

    const [routes, setRoutes] = useState([...initData])

    const [filterRoutes, setFilterRoutes] = useState([...initData])

    useEffect(() => {
        if (props.showRoute) {
            fetch2()
        }
    }, [props.showRoute])

    const fetch2 = () => {
        setLoading(true)
        let data = new FormData()
        data.append('user_id','34')
        Axios.post(
            Config.BASE_URL + 'get_routes_with_agent.php', data, Config.HEADERS
        ).then(res => {
            // console.log("routeList=", res)
            setRoutes(res.data)
            setLoading(false)
        }).catch(err => {
            console.error(err)
            setLoading(false)
        })
    }

    const setRoute = (item) => {
        props.setRoute(item)
        props.handleClose()
        // console.log(item)
    }

    const filter = (e) => {
        let char = e.target.value.toUpperCase()
        // console.log(char)
        var result = []
        routes.map(item => {
            if (item.source.toUpperCase().includes(char) || item.destination.toUpperCase().includes(char)) {
                result.push(item)
            }
        })

        setFilterRoutes(result)
    }

    return (
        <Modal
            dialogClassName="full_screen_modal"
            // fullscreen={true}
            show={props.showRoute} 
            onHide={props.handleClose}>

            <Modal.Header closeButton>
                <div className=" d-flex align-items-center w-100">
                    <IoListCircle size="28" />
                    <div className="input-group input-group-sm ml-3 ">
                        <input onChange={filter} type="text" className="form-control" aria-label="Sizing example input" />
                    </div>
                </div>

            </Modal.Header>

            <Modal.Body >
                {
                    isLoading ? <BarLoader color={"#7BDDFF"} css={override} size={150} loading={isLoading} /> :
                        <div className="">
                            {
                                filterRoutes.length > 0 ?
                                    filterRoutes.map((item, inx) => (
                                        <div
                                            onClick={() => setRoute(item)}
                                            key={inx}
                                            className="d-flex align-items-center mb-3">
                                            <IoNavigateCircleOutline size="28" />
                                            <span className="ml-3 w-100">{item.source} - {item.destination}</span>
                                            <IoArrowUndo size="26" />
                                        </div>
                                    ))
                                    : ""
                            }
                        </div>

                }

            </Modal.Body>
        </Modal>
    );

}

export default ChooseRoute;