import { useState, useEffect } from 'react';

const Error403 = (props) => {

    return (
        <div className="col-12 d-flex justify-content-center h-100 bg_theme_color">
            <div className="align-self-center color-light">
                <h3>Oops!</h3>
                <h1 className="my-4 p-2 border border-light border_radius_20 border_weight_3 w_fit mx-auto">Error 403</h1>
                <h4>Access Forbidden</h4>

                <p className="mt-2">You are not authrized to access this service</p>
            </div>
        </div>
    );
};

export default Error403