var User = (function () {
    var isAuthorized = false
    var userId = -1
    var user_name = ""
    var token = ""
    var vat = 0.0
    var method = ""

    return {
        setAuthorize: (status) => {
            isAuthorized = status
        },
        setUserId: (id) => {
            userId = id
        },
        setName: (name)=>{
            user_name = name
        },
        setToken: (t) =>{
            token = t
        },
        setVat: (v) =>{
            vat = v
        },
        setPayemntMethod: (m) =>{
            method = m
        },

        getAuth: () =>  isAuthorized,
        getUserId: () => userId,
        getName: () => user_name,
        getToken: () => token,
        getVat: () => vat,
        getMethod: () => method,
    }
})();

export default User