import logo from './logo.svg';
import './Styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch } from "react-router-dom"
import Auth from './auth'
import OrderList from './Components/screens/order_list'
import Error403 from './Components/screens/403'
import Home from './Components'
import About from './Components/about'
import './Styles/custom_style.css';
import { ToastProvider } from 'react-toast-notifications';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ToastProvider>
          <Switch>
            <Route exact path="/" component={Auth} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/orderlist" component={OrderList} />
            <Route path="/about/:position" component={About} />
            <Route exact path="/403" component={Error403} />
          </Switch>
        </ToastProvider>

      </div>
    </BrowserRouter>
  );
}

export default App;
