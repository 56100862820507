import React, { Component } from 'react'
import { IoList } from 'react-icons/io5'
import {  Redirect } from 'react-router-dom';
import axios from 'axios';
import * as Config from './../../Constant/config'
import { BarLoader } from 'react-spinners';
import OrderItem from './order_list_item'
import User from '../../auth/auth_model';

import { css } from "@emotion/core";
const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        width: 100%
        `;

class OrderList extends Component {

    state = {
        OrderList: [],
        isLoadding: false
    }

    componentDidMount() {
        // console.log("user_details", User.getUserId())
        this.getDate()
    }

    getDate() {
        this.setState({
            ...this.state,
            isLoadding: true
        })

        let user_id = User.getUserId()
        axios.get(
            Config.BASE_URL + "order/get_orders.php?user_id=" + user_id
        ).then(res => {
            var data = this.state
            data.OrderList = res.data.data
            data.isLoadding = false
            console.log("data",res.data)
            this.setState(data)

        }).catch(err => {
            console.log(err)
            this.setState({
                ...this.state,
                isLoadding: false
            })
        })
    }

    onBackPress = () => {
        // Need to handle back pressclick 
    }

    onTimeOut = (id) => {
        let datas = this.state.OrderList;
        this.setState({
            isLoadding: true,
            OrderList: []
        })
        let temps = datas.filter(function(item){
            return item.id !== id
        })
        setTimeout(() => {
            this.setState({
                isLoadding: false,
                OrderList: temps
            })
        }, 500)

    }

    render() {
        console.log("user ",User.getVat())
        if (!User.getAuth()) {
            
            return <Redirect to="/" />
        }
        let isLoading = this.state.isLoadding
        let orderList = this.state.OrderList
        return (
            <div className="container px-2">

                <div className="row">
                    <div className="col-12 py-2">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex">

                                {/* <Link >
                                    <IoArrowBackOutline onClick={this.onBackPress} size="24" className="mr-2" />
                                </Link> */}

                                <h5 className="w-100 text-left">Order List</h5>
                            </div>
                            <IoList size={22} />
                        </div>
                    </div>
                </div>

                <div className="row mt-2">

                    <div className="col-12">

                        {
                            isLoading ? <BarLoader color={"#7BDDFF"} css={override} size={150} loading={isLoading} />
                                : orderList.length > 0 ?
                                    orderList.map((item, inx) => (
                                        <OrderItem vat={User.getVat()} payMethod={User.getMethod()} key={inx} onTimeOut={this.onTimeOut} exp_time={item.expiry_timestamp} data={item} />
                                    ))
                                    : <p>No Records Found</p>

                        }

                    </div>

                </div>

            </div>
        );
    }

}


export default OrderList;