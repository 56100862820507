import React, { Fragment, useEffect, useState } from "react"
import { IoBusOutline, IoStop } from 'react-icons/io5';
import SeatPanel from './seat_panel';
import Axios from 'axios'
import * as Config from './../../Constant/config'
import OperatorModel from './operatorModel'
import { BarLoader } from 'react-spinners';
import { css } from "@emotion/core";
import { initBuSchedule } from '../../Constant/constData';
import bus_icon from '../../assets/img/bus_icon_black.png';

const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        width: 100%
        `;

const BusSchedule = (props) => {

    const [isLoading, setLoading] = useState(false)

    const [filter, setFilter] = useState({
        operator: false,
        ac: false,
        non_ac: false
    })

    const [showModel, setShowModel] = useState(false)

    const [busSchedules, setSchedule] = useState([])

    const [acNonAcFilter, setacNonAcFilter] = useState([])

    const [operatorFilter, setOperatorFilter] = useState([])

    const [operatorList, setOperatorList] = useState([])

    useEffect(() => {
        if (busSchedules.length === 0) {
            getData()
        }
    }, [busSchedules.length])

    const getData = () => {
        setLoading(true)
        // console.log(props.formData)

        let data = new FormData()
        data.append('source', props.formData.source)
        data.append('destination', props.formData.destination)
        data.append('date', props.formData.date)
        data.append('user_role', props.formData.user_role)
        Axios.post(Config.BASE_URL + 'route_time.php', data,/*  Config.HEADERS */)
            .then(res => {
                console.log("Schedule", res)
                let data = res.data.map(item => {
                    return item
                }) 
                setSchedule(data)
                setOperatorFilter(data)
                setacNonAcFilter(data)

                var operators = []
                data.map(item=>{
                    if(operators.indexOf(item.name)===-1){
                        operators.push(item.name)
                    }
                });
                setOperatorList(operators)

                setLoading(false)
                // console.log("Schedule:", data)
            }).catch(err => {
                // console.log(err)
                setLoading(false)
            })
    }

    const showOperatorModel = () => {
        setShowModel(true)
        setFilter({
            operator: true, ac: false, non_ac: false
        })
    }

    const handleClose = () => {
        setShowModel(false)
    };

    const onOperatorChecked = (datas) => {
        // console.log("Checked:", datas)
        var arr = []
        if (datas.length > 0) {
            busSchedules.map(item => {
                datas.map(data => {
                    // let list = busSchedules.filter(item => item.name.toUpperCase() === data.toUpperCase())
                    if (item.name.toUpperCase() === data.toUpperCase()) {
                        arr.push(item)
                    }
                })
            })
        } else {
            arr = busSchedules
        }
        setOperatorFilter(arr)
        setacNonAcFilter(arr)
    }

    const ac_nonAc = (pram) => {
        // console.log(pram)
        let newList = operatorFilter.filter(item => (item.condition.toUpperCase() === pram.toUpperCase()))
        setacNonAcFilter(newList)

        if (pram === 'Ac') {
            setFilter({
                operator: false, ac: true, non_ac: false
            })
        } else {
            setFilter({
                operator: false, ac: false, non_ac: true
            })
        }
    }

    const showSeats = (id) => {
        let temp = busSchedules.map((item, inx) => {
            let tm = item
            if (tm.id === id) tm.isSeatShowing = !tm.isSeatShowing
            else tm.isSeatShowing = false
            return tm
        })
        // console.log(temp)
        setSchedule(temp)
    }

    // console.log(busSchedules.length)

    return (
        <div className="col-12">

            <div className="d-flex justify-content-between w-100">
                <button
                    type="button"
                    className="btn btn-light border w_15 mr-1 btn_noFocus p-0 bg-white">{busSchedules.length}</button>

                <button
                    type="button"
                    onClick={showOperatorModel}
                    className={"btn btn-light border w_35 mr-1 btn_noFocus p-0 "
                        + (filter.operator ? "bg_theme_color" : "bg-white")}>Operator</button>

                <button
                    type="button"
                    onClick={() => ac_nonAc('Ac')}
                    className={"btn btn-light border w-25 mr-1 btn_noFocus p-0 " + (filter.ac ? "bg_theme_color" : "bg-white")}>AC</button>

                <button
                    type="button"
                    onClick={() => ac_nonAc('Non Ac')}
                    className={"btn btn-light border w-25 btn_noFocus p-0 " + (filter.non_ac ? "bg_theme_color" : "bg-white")}>NON AC</button>
            </div>

            {/* Gabtoli-Mirpur-Mohakhali-Firmgate-Sahabag-Polton-Gulisthan-Motijhil */}
            {/* Scheduled Bus List */}
            <div className="d-flex flex-column mt-2">

                {
                    isLoading ? <BarLoader color={"#7BDDFF"} css={override} size={150} loading={isLoading} /> :
                        (acNonAcFilter.length > 0) ?
                            acNonAcFilter.map((item, inx) => (
                                <div key={inx} className="card mb-2 py-3 px-2  transition_anim car_bg">
                                    <div onClick={() => showSeats(item.id)} className="d-flex flex-column text-left">
                                        <div className="d-flex justify-content-between">
                                            <h6 className="mb-0">{item.name}</h6>
                                            <span>৳{~~item.price}</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>{item.condition}  {item.bus_info}</span> 
                                            {item.d_price>0?<span className="text-danger" style={{"text-decoration": "line-through"}}>৳{~~item.d_price}</span>:""}
                                        </div>

                                        

                                        <div className="d-flex justify-content-between mt-3">
                                            <div className="d-table">
                                                <span className="d-block">{item.available_seats} Seats Left {item.class}</span>
                                                <span className="d-block ">{item.coach}, {~~item.time_difference}hr</span>
                                            </div>

                                            <h6 className="mb-0 mt-3">{item.start_time}</h6>
                                        </div>

                                        <div className="d-flex mt-2">
                                            <marquee
                                                behavior="scroll"
                                                direction="left"
                                                scrollamount="3"
                                                className="w-100 text_theme_color mr-2"
                                            >
                                                {item.additional_info}
                                            </marquee>
                                            <img className="mt-1" src={bus_icon} width="16" height="16" />
                                            {/* <IoBusOutline size="24" /> */}
                                        </div>
                                    </div>

                                    < SeatPanel formData={props.formData} scheduleData={item} ShowSeats={item.isSeatShowing} />
                                </div>
                            )) : <p>No Records Found</p>
                }

            </div>

            <OperatorModel onChecked={onOperatorChecked} data={operatorList} showModel={showModel} onHide={handleClose} />
        </div>
    );
}

export default BusSchedule;