import React, { Component, Fragment } from 'react';
import { IoBusOutline, IoArrowBackOutline } from 'react-icons/io5'
import parser from "html-react-parser"
import { Link, useParams,Redirect } from 'react-router-dom'
import { aboutInit } from '../Constant/constData';
import * as Config from '../Constant/config';
import axios from 'axios';
import { BarLoader } from 'react-spinners';
import { css } from "@emotion/core";
import User from '../auth/auth_model';

const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        width: 100%
        `;
const contact_us = "<p>For any Queries, please contact<br/> us on the below customer care<br/> numbers:</p>\r\n\r\n <p>Customer Care Number:<br/> +8801740062600<p/>\r\n\r\n <p>You can also send us email on the<br/> below mail ID –</p>\r\n\r\n <p>support@inyek.com<br/> Contact us</p>"
class About extends Component {

    state = {
        isLoading: false,
        title: "",
        data: ""
    }

    componentDidMount() {
        const { position } = this.props.parms
        // console.log(User.getAuth())
        if(position.toString()=="contact"){
            this.setState({
                title: "Contact Us",
                data : contact_us
            })
            return;
        }
        this.setState({
            isLoading: true
        })
        this.getPageData(position)
    }

    getPageData = (posi) => {
		let data = new FormData()
        data.append('position', posi)
        axios.post(Config.BASE_URL + "conditions.php", data, Config.HEADERS)
            .then(res => {
                // console.log(res.data.data[0])
                this.setState({
                    isLoading: false,
                    data: res.data.data[0].text,
                    title: res.data.data[0].title
                })
            }).catch(err => {
                // console.log(err)
                this.setState({
                    isLoading: false,
                })
            })
    }

    onBackPress = () => {

    }

    render() {
        if (!User.getAuth()) {
            // console.log(User.getAuth())
            return <Redirect to="/" />
        }
        return (
            <Fragment>

                <div className="container px-2">

                    <div className="row">
                        <div className="col-12 py-2">
                            <div className="d-flex justify-content-between">
                                {/* <Link >
                                    <IoArrowBackOutline onClick={this.onBackPress} size="24" className="mr-2" />
                                </Link> */}
                                <h5 className="w-100 text-left">{this.state.title}</h5>
                                <IoBusOutline size={22} />
                            </div>
                        </div>
                    </div>

                    {
                        this.state.isLoading ?
                            <div className="mt-5">
                                <BarLoader color={"#7BDDFF"} css={override} size={150} loading={this.state.isLoading} />
                            </div>
                            : <div className="row mt-3">
                                {/* {this.state.data} */}
                                <div className="text-left px-3">
                                    {parser(this.state.data)}
                                </div>

                            </div>
                    }
                </div>
            </Fragment>
        );
    }
}

export default (props) => (<About {...props} parms={useParams()} />);