import React, { Fragment, useEffect, useState } from "react"
import { Link, useHistory } from 'react-router-dom';
import * as Config from '../../Constant/config';
import axios from 'axios';
import { initCounter } from '../../Constant/constData';
import { useToasts } from 'react-toast-notifications';
import { BarLoader } from 'react-spinners';
import { css } from "@emotion/core";
import User from '../../auth/auth_model';
import moment from 'moment'

const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        width: 100%
        `;
/* 
  props contain-> 
    1. scheduleData
    2. selectedSeats
    3. formData
*/
const TotalCalcuation = (props) => {
    const history = useHistory()

    const { addToast } = useToasts()

    const [boardingPoint, setBoarding] = useState([])

    const [droppingPoint, setDropping] = useState([])

    const [isBooking, setIsBooking] = useState(false)

    const [selected_B_D_point, set_B_D_point] = useState({
        d_point: undefined,
        b_point: undefined
    })

    const [totalFormData, setTotalData] = useState({
        boarding_point: -1,
        dropping_point: -1,
        name: User.getName(),
        phone: "",
        gander: ""
    })

    useEffect(() => {
        getBoardingDroppingPoint()
    }, [])

    const getBoardingDroppingPoint = () => {
        let request = [
            axios.get(
                Config.BASE_URL + 'get_counters.php?schedule_id=' + props.scheduleData.id + '&type=' + "boarding", Config.HEADERS
            ),
            axios.get(
                Config.BASE_URL + 'get_counters.php?schedule_id=' + props.scheduleData.id + '&type=' + "dropping", Config.HEADERS
            ),
        ]

        axios.all(request)
            .then(axios.spread((boarding, dropping) => {
                // console.log("boarding", boarding.data)
                // console.log("dropping", dropping.data)
                setBoarding(boarding.data.data)
                setDropping(dropping.data.data)
            })).catch(err => {
                // console.log(err)
                setBoarding(initCounter)
                setDropping(initCounter)
            })
    }

    function bookNow(e) {
        e.preventDefault()

        // console.log(totalFormData) 
        // return

        let user_id = User.getUserId()
        let seats = props.selectedSeats.map(seat => {
            return seat.seat_number
        })
        if (totalFormData.boarding_point === -1) {
            addToast("Please Select Boarding Point", { appearance: 'warning', autoDismiss: true, })
            return
        }
        else if (totalFormData.name.trim().length === 0) {
            addToast("Please Input Your Name", { appearance: 'warning', autoDismiss: true, })
            return
        } else if (totalFormData.phone.trim().length === 0) {
            addToast("Please Input Your Phone Number", { appearance: 'warning', autoDismiss: true, })
            return
        } else if (totalFormData.gander.trim().length === 0) {
            addToast("Please Your Gander", { appearance: 'info', autoDismiss: true, })
            return
        }



        setIsBooking(true)

        let order_number = ~~(Math.random() * (9999999 - 100000))
        // console.log(order_number)
        let b_point = boardingPoint.find(item => item.id == totalFormData.boarding_point)
        let d_point = droppingPoint.find(item => item.id == totalFormData.dropping_point)
        // console.log(b_point)
        // console.log(d_point)
        // return
        let data = new FormData()
        data.append('user_id', user_id)
        data.append('route_schedule_id', props.scheduleData.id)
        data.append('bus_id', props.scheduleData.bus_id)
        data.append('seats', seats)
        data.append('journey_date', props.formData.date)
        data.append('journey_time', props.scheduleData.start_time)
        data.append('phone', totalFormData.phone)
        data.append('b_point', b_point.name)
        data.append('d_point', d_point ? d_point.name : "")
        data.append('order_number', order_number)
        data.append('route_id', props.scheduleData.route_id)
        data.append('ticket_price', props.scheduleData.price)
        data.append('total_price', getTotalPrice())
        data.append('total_discount', "0")
        data.append('order_username', totalFormData.name)
        data.append('gender', totalFormData.gander)
        data.append('_type', props.scheduleData.api_type)
        data.append('b_point_id', totalFormData.boarding_point)
        data.append('d_point_id', totalFormData.dropping_point)

        // console.log(data)
        axios.post(Config.BASE_URL + "order/push.php", data, Config.HEADERS)
            .then(res => {
                console.log(res)
                addToast("Booked Successfull!", { appearance: 'success', autoDismiss: true, })
                history.push("/orderlist")
                setIsBooking(false)
            }).catch(err => {
                addToast("Booking Failed!", { appearance: 'error', autoDismiss: true, })
                setIsBooking(false)
                console.log(err)
            })

    }

    function handleOnChange(e) {
        let valu = e.target.value
        let temp = totalFormData
        temp[e.target.name] = valu
        // console.log("target", valu)
        // console.log("boarding", boardingPoint)
        // console.log("dropping", droppingPoint)
        if (e.target.name === "boarding_point") {
            let point = boardingPoint.find((item) => item.id == ~~valu)
            set_B_D_point({
                ...selected_B_D_point,
                b_point: point
            })
        }
        if (e.target.name === "dropping_point") {
            let point = droppingPoint.find((item) => item.id == ~~valu)
            set_B_D_point({
                ...selected_B_D_point,
                d_point: point
            })
        }

        setTotalData(temp)
    }

    const getTotalPrice = () => {
        let b_price = selected_B_D_point.b_point ? ~~(selected_B_D_point.b_point.amount) : 0
        let d_price = selected_B_D_point.d_point ? ~~(selected_B_D_point.d_point.amount) : 0
        return props.selectedSeats.reduce((a, seat) => a = a + seat.fare + b_price + d_price, 0)
    }

    // var e = moment(new Date()).format("DD-MM-YYYY") + " 10:00 PM"
    // var d = moment(e).add(22, 'minutes').format("hh:mm A")
    // console.log("Schedule Data", selected_B_D_point)

    function getBoardingDroppingPointPrice(s_price) {
        var str = "" + s_price
        var price = ~~s_price
        if (selected_B_D_point.b_point) {
            price = price + (~~selected_B_D_point.b_point.amount)
            let p = ((~~selected_B_D_point.b_point.amount) === 0) ? "" : ((~~selected_B_D_point.b_point.amount) < 0) ? "-" + (selected_B_D_point.b_point.amount) : "+" + (selected_B_D_point.b_point.amount)
            str = str + "" + p
        }
        if (selected_B_D_point.d_point) {
            price = price + (~~selected_B_D_point.d_point.amount)
            let p = (~~selected_B_D_point.d_point.amount) === 0 ? "" : (~~selected_B_D_point.d_point.amount) < 0 ? "-" + selected_B_D_point.d_point.amount : "+" + selected_B_D_point.d_point.amount

            str = str + "" + p
        }
        return "(" + str + ")=" + price
    }

    return (
        <Fragment>
            {/* Seats Claculation */}
            {
                props.selectedSeats.length > 0 ?
                    <div className="d-flex flex-column mt-3">
                        <div className="card mb-2 py-2 px-3 ">
                            {
                                props.selectedSeats.map((seat, idx) => (
                                    <div key={idx} className="d-flex justify-content-between">

                                        <span>{seat.seat_number.toUpperCase()}</span>
                                        <span>{props.E_Class}</span>
                                        <span>{getBoardingDroppingPointPrice(seat.fare)} TK.</span>
                                    </div>
                                ))
                            }
                        </div>

                        <h6 className="text-right px-3">Total: {getTotalPrice()} TK.</h6>

                        <form onSubmit={bookNow} onChange={handleOnChange} className="d-flex flex-column">
                            <span className="text-left">Boarding point</span>

                            <select name="boarding_point" className="form-select border outline_none px-1 py-1" defaultValue="-1">
                                <option value="-1">Select Boarding Point</option>
                                {
                                    boardingPoint.length > 0 ?
                                        boardingPoint.map((point, inx) => (
                                            <option key={inx} value={point.id}>
                                                [{moment(moment(new Date()).format("DD-MM-YYYY") + " 10:00 PM").add(point.reporting_time, 'minutes').format("hh:mm A")}] {point.amount === 0 ? "" : "[" + point.amount + " BDT]"} {point.name}
                                            </option>
                                        ))
                                        : ""
                                }
                            </select>

                            {
                                droppingPoint.length > 0 ?
                                    <dvi className="w-100 text-left">
                                        <span className="text-left mt-2">Dropping Point</span>

                                        <select name="dropping_point" className="form-select border outline_none px-1 py-1" defaultValue="-1">
                                            <option value="-1">Select Dropping Point</option>
                                            {droppingPoint.map((point, inx) => (
                                                <option key={inx} value={point.id}>
                                                    [{moment(moment(new Date()).format("DD-MM-YYYY") + " 10:00 PM").add(point.reporting_time, 'minutes').format("hh:mm A")}] {point.amount === 0 ? "" : "[" + point.amount + " BDT]"} {point.name}
                                                </option>
                                            ))}
                                        </select>
                                    </dvi>
                                    : ""
                            }



                            <p className="text-left my-2">Passenger details <span className="font_12 text_light_ash">(will be sent to the Passenger)</span></p>

                            <div className="input-group input-group-sm flex-nowrap mb-2">
                                <input value={User.getName()} name="name" type="text" className="form-control outline_none py-0 px-2" placeholder="Name" />
                            </div>

                            <div className="input-group input-group-sm flex-nowrap mb-2">
                                <span className="input-group-text px-1 py-0" id="addon-wrapping" style={{
                                    borderRight: "0px",
                                    borderRadius: "3px 0px 0 3px"
                                }}>+880</span>
                                <input /* value={User.getName()}  */ name="phone" type="tel" className="form-control outline_none" placeholder="Phone" aria-label="Username" aria-describedby="addon-wrapping" />
                            </div>

                            <div className="d-flex">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="gander" id="inlineRadio1" value="male" />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="gander" id="inlineRadio2" value="female" />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="gander" id="inlineRadio3" value="other" />
                                    <label className="form-check-label" htmlFor="inlineRadio3">Other</label>
                                </div>
                            </div>

                            <p className="text-left font_12 my-1">By proceding, You are agree to our <Link to="/about/T&C INVOICE" className="text_theme_color">Terms &amp; Condition</Link></p>

                            {
                                isBooking ? <div className="mt-3 mb-4 mx-0 col-12"> <BarLoader color={"#7BDDFF"} css={override} size={150} loading={isBooking} /></div>
                                    : <button type="submit" className="btn btn-primary btn_noFocus btn-m mt-3 bg_theme_color border">Book Now</button>
                            }

                        </form>
                    </div>
                    : ""

            }

        </Fragment>
    )
}

export default TotalCalcuation;