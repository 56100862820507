

export const initScheduleDateRange = {
    "start_date": "2020-01-01",
    "end_date": "2021-06-01",
    "seat_limit": "30",
    "response": "true"
}

export const initCampaign = [
    {
        "id": "46",
        "title": "efscx",
        "text": "",
        "link": "https://bonikbarta.net/",
        "src": "uploads/Screenshot_214.png",
        "position": "Bus ticket page",
        "action": "",
        "expiry_date": null,
        "created_at": null
    },
    {
        "id": "47",
        "title": "sss",
        "text": "",
        "link": "",
        "src": "uploads/Screenshot_234.png",
        "position": "Bus ticket page",
        "action": "Pay Bill",
        "expiry_date": null,
        "created_at": null
    },
    {
        "id": "55",
        "title": "prothomalo sports",
        "text": "",
        "link": "",
        "src": "uploads/Screenshot_202.png",
        "position": "Bus ticket page",
        "action": "",
        "expiry_date": null,
        "created_at": null
    },
    {
        "id": "56",
        "title": "efscx",
        "text": "",
        "link": "",
        "src": "uploads/Screenshot_184.png",
        "position": "Bus ticket page",
        "action": "Registration",
        "expiry_date": null,
        "created_at": null
    }
]

export const initOrdersData = [
    {
        "id": "13",
        "user_id": "34",
        "route_id": "1",
        "schedule_id": "1",
        "coach_id": "22",
        "coach_no": "B-1234",
        "bus_id": "1",
        "agent_id": "33",
        "company_name": null,
        "ticket_id": null,
        "route_name": null,
        "party_name": "inyek",
        "order_number": "1790134",
        "total_seats": "0",
        "seats": "B1",
        "journey_date": "2021-05-10",
        "journey_time": "06:30:00",
        "order_date": "2021-05-09",
        "order_time": "12:59:27",
        "expiry_timestamp": "1620543927",
        "b_point": "dkhnn 12.9889879",
        "d_point": "Dinajpur",
        "payment_method": "none",
        "payment_status": "pending",
        "booking_type": "booked",
        "agent_name": "Binimoy Paribahan",
        "bus_condition": "Non Ac",
        "service_charge": "0.00",
        "service_vat": "0.00",
        "subtotal": "550.00",
        "total_price": "550.00"
    },
    {
        "id": "4",
        "user_id": "34",
        "route_id": "1",
        "schedule_id": "18",
        "coach_id": "27",
        "coach_no": "SSS 2",
        "bus_id": "10",
        "agent_id": "34",
        "company_name": null,
        "ticket_id": null,
        "route_name": null,
        "party_name": "inyek",
        "order_number": "7290134",
        "total_seats": "1",
        "seats": "A1",
        "journey_date": "2021-05-04",
        "journey_time": "01:00:00",
        "order_date": "2021-05-03",
        "order_time": "23:58:27",
        "expiry_timestamp": "1620065067",
        "b_point": "Gaptoli bus stand-12:45 PM ",
        "d_point": "Rangpur",
        "payment_method": "nagad",
        "payment_status": "confirmed",
        "booking_type": "booked",
        "agent_name": "Sakal Sandhya Enterprise",
        "bus_condition": "Non Ac",
        "service_charge": "5.00",
        "service_vat": "0.25",
        "subtotal": "10.00",
        "total_price": "14.00"
    }
]

export const initCounter = [
    {
        amount: "23",
        id: "2",
        name: "Test3",
        reporting_time: "14"
    },
    {
        amount: "23",
        id: "2",
        name: "Test3",
        reporting_time: "14"
    }
]

export const initBuSchedule = [
    {
        "id": "1",
        "time": "06:30:00",
        "start_time": "06:30 am",
        "arrival_time": "10:00 am",
        "price": "550.00",
        "d_price": 0,
        "bus_id": 0,
        "bus_name": "",
        "bus_model": "123",
        "bus_info": "",
        "name": "Binimoy Paribahan",
        "agent_id": 0,
        "route_id": 0,
        "coach": "B-1234",
        "class": "B-Class",
        "condition": "Non Ac",
        "additional_info": "gabtoly-savar-ghatail-madhupur-tangail,",
        "user_discount": "40.00",
        "total_seats": 32,
        "available_seats": 32,
        "booked_seats": 0,
        "sold_seats": 0,
        "blocked_seats": 0,
        "route_name": "dhaka - rajshahi",
        "from_station_id": 0,
        "from_station_name": "",
        "to_station_id": 0,
        "to_station_name": "",
        "time_difference": 3.5,
        "api_type": "INYEK",
        "response": "true",
        isSeatShowing: false
    },
    {
        "id": "16",
        "time": "07:30:00",
        "start_time": "07:30 am",
        "arrival_time": "02:00 pm",
        "price": "400.00",
        "d_price": 0,
        "bus_id": 0,
        "bus_name": "",
        "bus_model": ".",
        "bus_info": "",
        "name": "Shovechcha",
        "agent_id": 0,
        "route_id": 0,
        "coach": "S.N. D-1235",
        "class": "E-Class",
        "condition": "Non Ac",
        "additional_info": "Sholakuri-Madhupur-Pakutia-Baipel-Abdullahpur-Mohakhali",
        "user_discount": "0.00",
        "total_seats": 37,
        "available_seats": 37,
        "booked_seats": 0,
        "sold_seats": 0,
        "blocked_seats": 0,
        "route_name": "dhaka - rajshahi",
        "from_station_id": 0,
        "from_station_name": "",
        "to_station_id": 0,
        "to_station_name": "",
        "time_difference": 6.5,
        "api_type": "INYEK",
        "response": "true",
        isSeatShowing: false
    },
    {
        "id": "18",
        "time": "13:00:00",
        "start_time": "01:00 pm",
        "arrival_time": "05:52 am",
        "price": "10.00",
        "d_price": 0,
        "bus_id": 0,
        "bus_name": "",
        "bus_model": "036",
        "bus_info": "",
        "name": "Sakal Sandhya Enterprise",
        "agent_id": 0,
        "route_id": 0,
        "coach": "SSS 2",
        "class": "B-Class",
        "condition": "Non Ac",
        "additional_info": "Gaptoli-paturia ghat-keshobpur",
        "user_discount": "1.00",
        "total_seats": 35,
        "available_seats": 35,
        "booked_seats": 0,
        "sold_seats": 0,
        "blocked_seats": 0,
        "route_name": "dhaka - rajshahi",
        "from_station_id": 0,
        "from_station_name": "",
        "to_station_id": 0,
        "to_station_name": "",
        "time_difference": 16.866666666666667,
        "api_type": "INYEK",
        "response": "true",
        isSeatShowing: false
    },
    {
        "id": "14",
        "time": "18:44:00",
        "start_time": "06:44 pm",
        "arrival_time": "06:44 am",
        "price": "500.00",
        "d_price": 0,
        "bus_id": 0,
        "bus_name": "",
        "bus_model": "SA-32",
        "bus_info": "",
        "name": "Mamun Enterprise",
        "agent_id": 0,
        "route_id": 0,
        "coach": "M-533",
        "class": "A",
        "condition": "Ac",
        "additional_info": "Test",
        "user_discount": "0.00",
        "total_seats": 42,
        "available_seats": 42,
        "booked_seats": 0,
        "sold_seats": 0,
        "blocked_seats": 0,
        "route_name": "dhaka - rajshahi",
        "from_station_id": 0,
        "from_station_name": "",
        "to_station_id": 0,
        "to_station_name": "",
        "time_difference": 12,
        "api_type": "INYEK",
        "response": "true",
        isSeatShowing: false
    },
    {
        "id": "17",
        "time": "22:00:00",
        "start_time": "10:00 pm",
        "arrival_time": "06:00 am",
        "price": "800.00",
        "d_price": 0,
        "bus_id": 0,
        "bus_name": "",
        "bus_model": ".",
        "bus_info": "",
        "name": "Shovechcha",
        "agent_id": 0,
        "route_id": 0,
        "coach": "SSE-1",
        "class": "B-Class",
        "condition": "Non Ac",
        "additional_info": "gabtoly-savar-ghatail-madhupur-tangail",
        "user_discount": "0.00",
        "total_seats": 34,
        "available_seats": 34,
        "booked_seats": 0,
        "sold_seats": 0,
        "blocked_seats": 0,
        "route_name": "dhaka - rajshahi",
        "from_station_id": 0,
        "from_station_name": "",
        "to_station_id": 0,
        "to_station_name": "",
        "time_difference": 8,
        "api_type": "INYEK",
        "response": "true",
        isSeatShowing: false
    }
]

export const aboutInit = {
    title: "Terms & Condition",
    data: "<p><strong>Registration and use of the Services&nbsp;</strong></p>\r\n\r\n<p><br />\r\nIn order to access/use or continue to access/use the Site and/or certain Services, you may be required to provide certain personal information. You agree to provide true, accurate, updated, and complete information when registering at the Site or for such Services.&nbsp;</p>\r\n"
}

export const initSeat = [
    {
        "seat_id": "65",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "#",
        "row_number": "0",
        "column_number": "4",
        "row_column": "0,4",
        "status": -1
    },
    {
        "seat_id": "66",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "A1",
        "row_number": "1",
        "column_number": "0",
        "row_column": "1,0",
        "status": -1
    },
    {
        "seat_id": "67",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "A2",
        "row_number": "1",
        "column_number": "1",
        "row_column": "1,1",
        "status": -1
    },
    {
        "seat_id": "68",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "A3",
        "row_number": "1",
        "column_number": "3",
        "row_column": "1,3",
        "status": -1
    },
    {
        "seat_id": "69",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "A4",
        "row_number": "1",
        "column_number": "4",
        "row_column": "1,4",
        "status": -1
    },
    {
        "seat_id": "70",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "B1",
        "row_number": "2",
        "column_number": "0",
        "row_column": "2,0",
        "status": -1
    },
    {
        "seat_id": "71",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "B2",
        "row_number": "2",
        "column_number": "1",
        "row_column": "2,1",
        "status": -1
    },
    {
        "seat_id": "72",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "B3",
        "row_number": "2",
        "column_number": "3",
        "row_column": "2,3",
        "status": -1
    },
    {
        "seat_id": "73",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "B4",
        "row_number": "2",
        "column_number": "4",
        "row_column": "2,4",
        "status": -1
    },
    {
        "seat_id": "74",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "C1",
        "row_number": "3",
        "column_number": "0",
        "row_column": "3,0",
        "status": -1
    },
    {
        "seat_id": "75",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "C2",
        "row_number": "3",
        "column_number": "1",
        "row_column": "3,1",
        "status": -1
    },
    {
        "seat_id": "76",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "C3",
        "row_number": "3",
        "column_number": "3",
        "row_column": "3,3",
        "status": -1
    },
    {
        "seat_id": "77",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "C4",
        "row_number": "3",
        "column_number": "4",
        "row_column": "3,4",
        "status": -1
    },
    {
        "seat_id": "78",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "D1",
        "row_number": "4",
        "column_number": "0",
        "row_column": "4,0",
        "status": -1
    },
    {
        "seat_id": "79",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "D2",
        "row_number": "4",
        "column_number": "1",
        "row_column": "4,1",
        "status": -1
    },
    {
        "seat_id": "80",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "D3",
        "row_number": "4",
        "column_number": "3",
        "row_column": "4,3",
        "status": -1
    },
    {
        "seat_id": "81",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "D4",
        "row_number": "4",
        "column_number": "4",
        "row_column": "4,4",
        "status": -1
    },
    {
        "seat_id": "82",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "E1",
        "row_number": "5",
        "column_number": "0",
        "row_column": "5,0",
        "status": -1
    },
    {
        "seat_id": "83",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "E2",
        "row_number": "5",
        "column_number": "1",
        "row_column": "5,1",
        "status": -1
    },
    {
        "seat_id": "84",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "E3",
        "row_number": "5",
        "column_number": "3",
        "row_column": "5,3",
        "status": -1
    },
    {
        "seat_id": "85",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "E4",
        "row_number": "5",
        "column_number": "4",
        "row_column": "5,4",
        "status": -1
    },
    {
        "seat_id": "86",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "F1",
        "row_number": "6",
        "column_number": "0",
        "row_column": "6,0",
        "status": -1
    },
    {
        "seat_id": "87",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "F2",
        "row_number": "6",
        "column_number": "1",
        "row_column": "6,1",
        "status": -1
    },
    {
        "seat_id": "88",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "F3",
        "row_number": "6",
        "column_number": "3",
        "row_column": "6,3",
        "status": -1
    },
    {
        "seat_id": "89",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "F4",
        "row_number": "6",
        "column_number": "4",
        "row_column": "6,4",
        "status": -1
    },
    {
        "seat_id": "90",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "G1",
        "row_number": "7",
        "column_number": "0",
        "row_column": "7,0",
        "status": -1
    },
    {
        "seat_id": "91",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "G2",
        "row_number": "7",
        "column_number": "1",
        "row_column": "7,1",
        "status": -1
    },
    {
        "seat_id": "92",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "G3",
        "row_number": "7",
        "column_number": "3",
        "row_column": "7,3",
        "status": -1
    },
    {
        "seat_id": "93",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "G4",
        "row_number": "7",
        "column_number": "4",
        "row_column": "7,4",
        "status": -1
    },
    {
        "seat_id": "94",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "H1",
        "row_number": "8",
        "column_number": "0",
        "row_column": "8,0",
        "status": -1
    },
    {
        "seat_id": "95",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "H2",
        "row_number": "8",
        "column_number": "1",
        "row_column": "8,1",
        "status": -1
    },
    {
        "seat_id": "96",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "H3",
        "row_number": "8",
        "column_number": "3",
        "row_column": "8,3",
        "status": -1
    },
    {
        "seat_id": "97",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "H4",
        "row_number": "8",
        "column_number": "4",
        "row_column": "8,4",
        "status": -1
    },
    {
        "seat_id": "98",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "I1",
        "row_number": "9",
        "column_number": "0",
        "row_column": "9,0",
        "status": -1
    },
    {
        "seat_id": "99",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "I2",
        "row_number": "9",
        "column_number": "1",
        "row_column": "9,1",
        "status": -1
    },
    {
        "seat_id": "100",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "I3",
        "row_number": "9",
        "column_number": "3",
        "row_column": "9,3",
        "status": -1
    },
    {
        "seat_id": "101",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "I4",
        "row_number": "9",
        "column_number": "4",
        "row_column": "9,4",
        "status": -1
    },
    {
        "seat_id": "102",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "J1",
        "row_number": "10",
        "column_number": "0",
        "row_column": "10,0",
        "status": -1
    },
    {
        "seat_id": "103",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "J2",
        "row_number": "10",
        "column_number": "1",
        "row_column": "10,1",
        "status": -1
    },
    {
        "seat_id": "104",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "J3",
        "row_number": "10",
        "column_number": "3",
        "row_column": "10,3",
        "status": -1
    },
    {
        "seat_id": "105",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "J4",
        "row_number": "10",
        "column_number": "4",
        "row_column": "10,4",
        "status": -1
    },
    {
        "seat_id": "106",
        "bus_id": "3",
        "fare": 700,
        "seat_number": "J5",
        "row_number": "10",
        "column_number": "2",
        "row_column": "10,2",
        "status": -1
    }
]
