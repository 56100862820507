import React, { Component, Fragment } from 'react';
import {
    FaCalendarAlt
} from 'react-icons/fa';
import { IoMenu } from 'react-icons/io5'
import axios from "axios"
import * as Config from "../Constant/config"
import ChooseRoute from './screens/choose_route';
import BusSchedule from './screens/bus_schedule';

import { BarLoader } from 'react-spinners';
import { css } from "@emotion/core";

import { createMuiTheme } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from '@date-io/date-fns';
import { useToasts } from 'react-toast-notifications';
import { Dropdown } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import User from '../auth/auth_model';
// import momnet from 'momnet';

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#7BDDFF',
            },
        },
        MuiPickersDay: {
            day: {
                color: '#000',
            },
            daySelected: {
                backgroundColor: '#7BDDFF',
            },
            dayDisabled: {
                color: '#0f0f0f',
            },
            current: {
                color: '#7BDDFF',
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: '#000',
            },
        },

    },
});

function withProps(Component) {
    return (props) => {
        const toastFuncs = useToasts()
        return <Component {...props} {...toastFuncs} />;
    }
}

const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        width: 100%
        `;

class Index extends Component {

    state = {
        isShowSelectRouteDialog: false,
        isShowBusSchedule: false,
        isLoading: false,
        selectedDate: new Date(),
        pickFrom: new Date(),
        selectedDateMenu: 1,
        busScheduleData: {
            source: "",
            destination: "",
            date: "",
            user_role: "0"
        },
        dateRange: {
            "start_date": "",
            "end_date": "",
            "seat_limit": "0",
            "response": "true"
        } /* initScheduleDateRange */, // the inital data for test purpose only
        campaing: [] // the inital data for test purpose only
    }

    componentDidMount() {
        this.getDatas()
    }

    getDatas = () => {

        this.setState({
            ...this.state,
            isLoading: true
        })

        let promoData = new FormData()
        promoData.append('position', 'Bus ticket page')
        var reqeust = [
            axios.post(
                Config.BASE_URL + 'get_date_range.php', {}, Config.HEADERS
            ),
            axios.post(Config.BASE_URL + "promotions.php",
                promoData, Config.HEADERS
            )
        ]

        axios.all(reqeust)
            .then(axios.spread((dateRange, promitions) => {
                // console.log("offDays=", range)
                // console.log("promitions=", promitions.data.data)
                console.log("dateRange=", dateRange.data[0])
                this.setState({
                    ...this.state,
                    campaing: promitions.data.data,
                    isLoading: false,
                    dateRange: dateRange.data[0]
                })

                // if (range.length > 0) {
                //     this.setState()
                // }
            })).catch(err => {
                // console.log("err=", err)
                this.setState({
                    ...this.state,
                    isLoading: false
                })
            })

    }

    handleClose = () => this.setState({
        isShowSelectRouteDialog: false
    });
    handleShow = () => this.setState({
        isShowSelectRouteDialog: true
    });

    searchSchedule = () => {
        let source = this.state.busScheduleData.source
        let desti = this.state.busScheduleData.destination
        if (source.length == 0 && desti.length == 0) {
            this.props.addToast("Please Select a Route First", { appearance: 'info', autoDismiss: true, })
            return
        }
        console.log(this.state.selectedDate)
        var schedule = this.state.busScheduleData
        schedule.date = this.parsedDate(this.state.selectedDate)
        this.setState({
            ...this.state,
            isShowBusSchedule: !this.state.isShowBusSchedule,
            busScheduleData: schedule
        })
    };

    setRoute = (item) => {
        // console.log(item)
        var schedule = this.state.busScheduleData
        schedule.source = item.source
        schedule.destination = item.destination

        this.setState({
            ...this.state,
            busScheduleData: schedule
        })
    }

    pickDate = (date, menu_num) => {
        // console.log("date_picker", new Date(date))
        this.setState({
            ...this.state,
            pickFrom: new Date(date),
            selectedDate: new Date(date),
            selectedDateMenu: menu_num
        }, () => {
            if (menu_num === 3) document.getElementById("picker").click()
        })

    }

    onDatePicked = (date) => {
        // console.log("picked_date",this.parsedDate(new Date(date)))
        this.setState({
            ...this.state,
            selectedDate: date
        })
    }

    parsedDate(date) {
        return Intl.DateTimeFormat('fr-CA', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(date);
    }

    getSelectedDate(dateSrt) {

        var date = new Date(dateSrt)
        var weekDay = new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date)
        var month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date)
        var day = new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(date)
        return (
            <div className="col-5 text-left mt-3">
                <h4>{day}</h4>
                <h6 className="text-muted mt-3">{month}, {weekDay}</h6>
            </div>
        )
    }

    // Set custom Dropdown 
    CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            className="btn mr-2 h-100 border "
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            {/* &#x25bc; */}
        </a>
    ));

    render() {
        // var d = moment(momnet(new Date()).format("DD-MM-YYYY")+" 10:00 PM").add(22,'minutes').format("hh:mm A")
        // console.log("DateParse",d)

        if (!User.getAuth()) {
            // console.log(User.getAuth())
            return <Redirect to="/" />
        }

        let showRoute = this.state.isShowSelectRouteDialog
        let showBusSchedule = this.state.isShowBusSchedule
        let loadding = this.state.isLoading
        let source = this.state.busScheduleData.source
        let desti = this.state.busScheduleData.destination
        let pickFrom = this.state.pickFrom
        let selectedDate = this.state.selectedDate
        let menu_id = this.state.selectedDateMenu
        let campaign = this.state.campaing
        let endDate = this.state.dateRange.end_date
        console.log(endDate.toString())
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={materialTheme}>
                    <DatePicker
                        minDate={ new Date()}
                        maxDate={new Date(endDate)}
                        value={pickFrom}
                        className="d-none"
                        id="picker"
                        onChange={this.onDatePicked} />
                </ThemeProvider>
                <div className="container px-2">
                    <div className="row">
                    </div>

                    <div className="row mt-3">
                        <div className="col-12 d-flex justify-content-between">

                            <button onClick={this.handleShow} type="button" className="btn btn-light w-100 text-left border border_theme_color bg-white py-2">
                                {
                                    (source || desti) ?
                                        "" + source + " - " + desti
                                        : "Select route"
                                }
                            </button>
                        </div>
                    </div>

                    <div className="row mt-4 d-flex align-items-center">
                        {this.getSelectedDate(selectedDate)}
                        <div className="col-7 d-flex align-items-end flex-column">
                            <button
                                onClick={() => this.pickDate(new Date().setDate(new Date().getDate() + 2), 3)}
                                className={"btn btn-light bg-white border-0 btn_noFocus p-0 d-flex align-items-center " + (menu_id === 3 ? "text_theme_color" : "")}
                            ><FaCalendarAlt className="mr-3" /> From Calender</button>
                            <div>
                                <hr />
                                <div className="d-inline-flex ">

                                    <button onClick={() => this.pickDate(new Date(), 1)} className={"btn btn-light bg-white border-0 btn_noFocus p-0 " + (menu_id === 1 ? "text_theme_color" : "")}>Today</button>
                                    <div className="w_2px bg_light_ash mx-4"></div>
                                    <button
                                        onClick={() => this.pickDate(new Date().setDate(new Date().getDate() + 1), 2)}
                                        className={"btn btn-light bg-white border-0 btn_noFocus  p-0 " + (menu_id === 2 ? "text_theme_color" : "")}>Tomorrow</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="d-flex justify-content-between mt-3 ">
                                <Dropdown>
                                    <Dropdown.Toggle as={this.CustomToggle} >
                                        <IoMenu size={24} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item ><Link to="/orderlist">Ordered List </Link></Dropdown.Item>
                                        <Dropdown.Item><Link to="/about/T&C INVOICE">Terms &amp; Condition</Link></Dropdown.Item>
                                        <Dropdown.Item><Link to="/about/About">About</Link></Dropdown.Item>
                                        <Dropdown.Item><Link to="/about/contact">Contact Us</Link></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <button type="button" className="btn w-100 border-0 bg_theme_color py-2" onClick={this.searchSchedule}>Search Buses</button>
                            </div>
                            <div className="d-flex justify-content-between mt-1">
                                <p className="text-primary fw-bolder">Inyek bus</p>
                                <p className="">Ticket Available To {this.state.dateRange.end_date}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {
                            showBusSchedule ? <BusSchedule formData={this.state.busScheduleData} /> :
                                loadding ? <div className="mt-3 col-12">
                                    <BarLoader color={"#7BDDFF"} css={override} size={150} loading={loadding} />
                                </div> :
                                    <div className="col-12">
                                        {
                                            campaign.length > 0 ?
                                                campaign.map((item, inx) => (
                                                    <div key={inx} className="card bg_theme_color mb-2">
                                                        <a href={item.link ? item.link : "#"} >
                                                            <img src={Config.IMAGE_URL + item.src} className="card-img h_120px" alt={item.title} />
                                                        </a>
                                                    </div>
                                                ))
                                                : <p>No Records Found</p>
                                        }
                                    </div>
                        }
                    </div>

                    <ChooseRoute showRoute={showRoute} handleClose={this.handleClose} setRoute={this.setRoute} />
                </div>
            </MuiPickersUtilsProvider>
        );
    }
}

export default withProps(Index);