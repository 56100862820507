export const BASE_URL = "https://app.inyek.com/app_api/app_inyek/"
export const IMAGE_URL = "https://inyek.com/admin/"
export const MAIN_URL = "https://inyek.com/"

export const HEADERS = {
    'Accept': 'application/json',
	'Content-Type': 'application/json',
	'Access-Control-Allow-Credentials': 'true',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE,OPTIONS',
	'Access-Control-Allow-Headers': 'Accept, Origin, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, X-Requested-With',
}
