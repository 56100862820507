import React, { useEffect, useState } from 'react';
import * as Config from '../../Constant/config';
import Moment from 'moment';

const OrderListItem = (props) => {

    const [_time, setTime] = useState((props.exp_time - Math.floor(Date.now() / 1000)))
    useEffect(() => {
        if (_time > 0) {
            setTimeout(() => {
                setTime(props.exp_time - Math.floor(Date.now() / 1000))
            }, 1000)
        }
    }, [_time])

    const getCount = (_time) => {
        if (_time == 0) {
            if (props.data.booking_type !== "booked") {
                return "00:00"
            } else {
                props.onTimeOut(props.data.id)
            }
        }
        let min = Math.floor((_time / 60))
        let sec = Math.floor(_time - (min * 60))

        var result = min + ":" + sec
        if (min < 10) {
            result = "0" + min + ":"
        }
        if (sec < 10) {
            result = result + "0" + sec
        } else {
            result = result + sec
        }
        return result
    }

    let host = window.location.href

    let method = props.payMethod
    let vatAmount = parseFloat((parseFloat(props.vat, 2) * parseFloat(props.data.total_price, 2)) / 100, 2)

    return (
        <div key={props.inx} className="card mb-2 py-2 px-2 transition_anim font_12">
            <div className="d-flex justify-content-between">
                <h6 className="mb-0">{props.data.agent_name}</h6>
                <h6 className="mb-0"><span style={{ "font-size": "14px", "font-weight": " 800" }}>৳</span>{~~props.data.total_price}</h6>
            </div>
            <div className="d-flex justify-content-between mt-3 ">
                <span>Journey Date: {props.data.journey_date}</span>
                <span>Time: {props.data.journey_time}</span>
            </div>
            <div className="d-flex justify-content-between mt-3 ">
                <span>{props.data.bus_condition}</span>
                <span>{props.data.route_name}</span>
            </div>
            <div className="d-flex flex-column mt-1 text-left">
                <span>Boarding Point - {props.data.b_point}</span>
                {props.data.d_point ? <span>Dropping Point - {props.data.d_point}</span> : ""}
            </div>

            <div className="d-flex mt-1 ">
                <div className="w_40 d-flex align-items-end">
                    <div className="d-flex flex-column text-left">
                        <span className="mb-1">Order No: {props.data.order_no}</span>
                        <span>{props.data.passenger_name}</span>
                        <span>{props.data.passenger_phone}</span>
                    </div>
                </div>

                <div className="w_20 d-flex align-items-end text-center">
                    <span className="mx-auto">
                        {getCount(_time)}
                    </span>
                </div>

                <div className="w_40 d-flex flex-column text-right">
                    <span>Seats-{props.data.seats}</span>
                    <span>Subtotal = {~~props.data.subtotal}</span>
                    <span>Service charge = {Math.round((parseFloat(props.data.service_charge, 2) + vatAmount))}</span>
                    <hr className="w-100 m-0" />
                    <h6 className="mb-0 font_14">Total = {Math.round((parseFloat(props.data.total_price, 2) + vatAmount))}</h6>
                </div>
                {
                    (props.data.booking_type === "booked") ? "" :
                        <div className="w_40 d-flex flex-column text-right">
                            <span>Seats-{props.data.seats}</span>
                            <span>icon</span>
                        </div>
                }


            </div>
            {
                (props.data.booking_type === "booked") ?
                    <a /* onClick={() => }  */ href={Config.BASE_URL + "payment/" + method + "/payment.php?order_no=" + props.data.order_no + "&payFor=BusTicket"
                        + "&payType=WEBAPI&returnURL=" + Buffer.from(host).toString('base64')} className="btn btn-primary btn_noFocus btn-m mt-3 bg_theme_color border">Pay Now</a>

                    : <div className="w-100">
                        <button type="button" className="btn btn-success btn_noFocus btn-sm mt-3 text-capitalize border">
                            {props.data.booking_type}
                        </button>
                        <p className="text-left mt-1">Purchase date {props.data.order_date} &amp; Time {Moment(props.data.order_date+" "+props.data.order_time).format("hh:mm a")}</p>
                    </div>
            }

        </div>
    )
}

export default OrderListItem